// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import type { Edges } from '../../types';
import styles from './Feed.module.scss';

type Props = {
  edges: Edges
};

const Feed = ({ edges }: Props) => (
  <div className={styles['feed']}>
    {edges.map((edge) => {
      const {
        title,
        socialImage,
        date,
        category,
        description
      } = edge.node.frontmatter;
      const { slug, categorySlug } = edge.node.fields;

      return (
        <div className={styles['feed__item']} key={slug}>
          <Link className={styles['feed__item-readmore']} to={slug}>
            <div className={styles['feed__item-image']}>
              <img src={socialImage.publicURL} alt={title} />
            </div>
          </Link>
          <div className={styles['feed__item-meta']}>
            <time className={styles['feed__item-meta-time']} dateTime={new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}>
              {new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
            </time>
            <span className={styles['feed__item-meta-divider']} />
            <span className={styles['feed__item-meta-category']}>
              <Link to={categorySlug} className={styles['feed__item-meta-category-link']}>{category}</Link>
            </span>
          </div>
          <h2 className={styles['feed__item-title']}>
            <Link className={styles['feed__item-title-link']} to={slug}>{title}</Link>
          </h2>
          <p className={styles['feed__item-description']}>{description}</p>
          <Link className={styles['feed__item-readmore']} to={slug}>Read</Link>
        </div>);
    })}
  </div>
);

export default Feed;
